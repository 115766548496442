.main {
  min-width: 100%;
  min-height: 100%
}

video {

  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-color: '#000';
  background-size: cover;
  filter: brightness(60%) opacity(96%);


}

.socials {
  width: 50px;
  height: auto;
  position: fixed;
  z-index: 10;
  top: calc(100vh / 2);
  left: 60px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 1700px) {
  .socials {
    left: 30px;
  }
}

@media (max-width: 1600px) {
  .socials {
    left: 10px;
    width: 30px;
  }
}

@media (max-width: 1024px) {
  .socials {
    display: none;
  }
}

.socials__item:not(:last-child) {
  margin-bottom: 20px;
}

.circle-button {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 1px solid #3c3c3b;
  box-shadow: 0 0 1px 0px #3c3c3b inset, 0 0 1px 0px #3c3c3b;
  position: relative;
}

.circle-button.orange {
  border: 1px solid #f3b451;
  box-shadow: 0 0 1px 0px #f3b451 inset, 0 0 1px 0px #f3b451;
}

.circle-button:hover:after {
  background: #61615f;
}

.circle-button.orange:hover:after {
  background: #1e1a23;
}

.circle-button:after {
  content: '';
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 50px;
  background: #1e1a23;
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  -webkit-transition: background .3s ease-in-out;
  -o-transition: background .3s ease-in-out;
  transition: background .3s ease-in-out;
}

.circle-button.orange:after {
  background: #5e3d2c;
}

.circle-button span {
  font-size: 18px;
  line-height: 18px;
  position: relative;
  z-index: 2;
  color: #cfcfcf;
  -webkit-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out;
}

.circle-button.orange span {
  color: #1f1f1f;
}

.circle-button:hover span {
  color: #fff;
}

.circle-button.orange:hover span {
  color: #1f1f1f;
}

.flex-sbc,
.flex-sbs,
.flex-sbe,
.flex-cc,
.flex-cs,
.flex-ce,
.flex-sc,
.flex-ss,
.flex-se,
.flex-ec,
.flex-es,
.flex-ee {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-sbc,
.flex-sbs,
.flex-sbe {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-cc,
.flex-cs,
.flex-ce {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-sc,
.flex-ss,
.flex-se {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-ec,
.flex-es,
.flex-ee {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex-sbc,
.flex-cc,
.flex-sc,
.flex-ec {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-sbs,
.flex-cs,
.flex-ss,
.flex-es {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-sbe,
.flex-ce,
.flex-se,
.flex-ee {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 1#5e3d2c 0 3px rgb(105, 54, 54);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffb221;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffb221;
}

ul.listmobile {
  margin: 0; /* remove default margin */
}

ul.listdesktop {
  margin: 0; /* remove default margin */
}

@media only screen and (max-width: 767px) {
  ul.listmobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: small;
  }
  
  ul.listdesktop {
    display: none; /* hide on mobile devices */
  }
}

@media only screen and (min-width: 768px) {
  ul.listmobile {
    display: none; /* hide on desktop devices */
  }
  
  ul.listdesktop {
    display: block; /* show on desktop devices */
  }
}

